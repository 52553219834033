
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { ApiBase, ApiOrderRequest } from "@/core/api";
import {
  OrderRequestPaymentMethod,
  OrderRequestPaymentType,
} from "@/core/directive/type/orderRequest";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  OrderRequestOverview,
  orderRequestOverviewData,
} from "@/core/directive/interface/orderRequest";
import WindowResize from "@/components/layout/WindowResize.vue";

export default defineComponent({
  components: { WindowResize },
  name: "order-request-update-payment-method-modal",
  props: {
    data: {
      type: Object as () => OrderRequestOverview,
      default: () => {
        return Object.assign({}, orderRequestOverviewData);
      },
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updatePaymentMethodRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      payment_method: "",
      payment_by: "",
      payment_person: "",
    });

    const rules = ref({
      payment_method: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      payment_by: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      payment_person: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      payment_method: [] as TaggingItem[],
      paymentMethod: new Map([]),
      payment_by: [] as TaggingItem[],
      paymentBy: new Map([]),
      payment_person: [] as TaggingItem[],
      paymentPersonLoading: false,
      paymentPersonLoaded: false,
      paymentPerson: new Map([]),
    });

    const paymentMethodChange = (e) => {
      formData.value.payment_by = "";
      formData.value.payment_person = "";
    };

    const paymentByChange = (e) => {
      formData.value.payment_person = "";
    };

    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.paymentPersonLoading) return cb([]);
      if (options.value.paymentPersonLoaded) {
        let filterArr: TaggingItem[] = options.value.payment_person.filter(
          (item) =>
            (item.value as string)
              .toUpperCase()
              .indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.paymentPerson.clear();
      options.value.paymentPersonLoading = true;
      const { data } = await ApiBase.getUserSourceData({
        max_item: "all",
        role_id: "12",
      });
      options.value.paymentPersonLoading = false;
      if (data.code == 0) {
        data.data.forEach((item) => {
          item.value = item.label;
        });
        options.value.payment_person = [...data.data];
        data.data.map((item) => {
          options.value.paymentPerson.set(item.value, item.label);
        });
        options.value.paymentPersonLoaded = true;
        const filterArr: TaggingItem[] = options.value.payment_person.filter(
          (item) =>
            (item.value as string)
              .toUpperCase()
              .indexOf(formData.value.payment_person.toUpperCase()) > -1
        );
        cb(filterArr);
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "order_request_payment_type",
          "order_request_payment_method",
        ],
      });
      if (data.code == 0) {
        options.value.payment_method =
          data.data.order_request_payment_type.items;
        data.data.order_request_payment_type.items.map((item) => {
          options.value.paymentMethod.set(item.value, item.label);
        });
        options.value.payment_by = data.data.order_request_payment_method.items;
        data.data.order_request_payment_method.items.map((item) => {
          options.value.paymentBy.set(item.value, item.label);
        });
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([getTaggingData()])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          updatePaymentMethod();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updatePaymentMethod = async () => {
      const params = formData.value;
      loading.value = true;
      const { data } = await ApiOrderRequest.saveOrderRequestV2Payment({
        merchant_request_id: route.params.id,
        payment_method: params.payment_method,
        payment_by: params.payment_by,
        payment_person: params.payment_person,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        const param = {
          payment_method: {
            label: options.value.paymentMethod.get(params.payment_method),
            value: params.payment_method,
          },
          payment_by: {
            label: options.value.paymentBy.get(params.payment_by),
            value: params.payment_by,
          },
          payment_person: params.payment_person,
        };
        emit("update-list", param);
        handleDiscard();
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleDiscard = () => {
      hideModal(updatePaymentMethodRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      options.value.paymentPersonLoaded = false;
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        if (props.data.relation_payment.payment_method) {
          formData.value.payment_method = props.data.relation_payment
            .payment_method as string;
        }
        if (props.data.relation_payment.payment_by) {
          formData.value.payment_by = props.data.relation_payment
            .payment_by as string;
        }
        if (props.data.relation_payment.payment_person) {
          formData.value.payment_person =
            props.data.relation_payment.payment_person;
        }
      }, 0);
    };

    onMounted(() => {
      modalShowListener(updatePaymentMethodRef.value, () => {
        init();
      });
      modalHideListener(updatePaymentMethodRef.value, () => {
        resetForm();
      });
      getFormInfo();
    });

    return {
      t,
      commonChangeDefaultDate,
      OrderRequestPaymentType,
      OrderRequestPaymentMethod,
      formRef,
      submitButtonRef,
      submitButton,
      updatePaymentMethodRef,
      props,
      loading,
      formData,
      rules,
      options,
      paymentMethodChange,
      paymentByChange,
      querySearchAsync,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
