
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import { ApiOrderRequest, ApiSalesOrders } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import UpdateAddress from "./UpdateAddress.vue";
import {
  CommonArrayToString,
  commonExportFile,
  formatDate,
  formatDateOriginTimeZone,
  formatDateTime,
} from "@/core/directive/function/common";
import { isAuditView } from "@/core/directive/function/order";
import {
  orderRequestOverviewData,
  OrderRequestInvoice,
  OrderRequestOverviewShowAddressTaxRates,
  orderRequestOverviewShowAddressData,
} from "@/core/directive/interface/orderRequest";
import {
  getMerchantOrderRouterPath,
  getOrderRequestProgressClass,
} from "@/core/directive/function/orderRequest";
import {
  OrderRequestPaymentMethod,
  OrderRequestPaymentType,
  OrderRequestProgress,
  OrderRequestType,
} from "@/core/directive/type/orderRequest";
import UpdateMerchant from "./UpdateMerchant.vue";
import UpdatePaymentMethod from "./UpdatePaymentMethod.vue";
import CommonTreeMenu from "@/components/layout/CommonTreeMenu.vue";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import WindowResize from "@/components/layout/WindowResize.vue";
import { printClose, printConnect } from "@/core/directive/function/print";

export default defineComponent({
  name: "order-request-detail",
  components: {
    UpdateAddress,
    UpdateMerchant,
    UpdatePaymentMethod,
    CommonTreeMenu,
    PermissionCommon,
    WindowResize,
  },
  setup() {
    const { t } = useI18n();

    const route = useRoute();

    const childMainRef = ref();

    const loading = ref(true);

    const disabledExportOrderNotice = ref<boolean>(false);

    const OrderRequestOverviewData = ref(
      Object.assign({}, orderRequestOverviewData)
    );

    const options = ref({
      orderRequestInvoice: [] as OrderRequestInvoice[],
      isEdit: false,
      tabIndex: 0,
      downloadDisabled: false,
    });

    const isShowPaymentInfomationTip = computed(() => {
      let flag = false;
      if (!isDraft.value) {
        if (
          OrderRequestOverviewData.value.relation_payment.payment_method === ""
        ) {
          flag = true;
        } else {
          if (
            OrderRequestOverviewData.value.relation_payment.payment_method ===
            OrderRequestPaymentType.DirectPayment
          ) {
            if (
              OrderRequestOverviewData.value.relation_payment.payment_by === ""
            ) {
              flag = true;
            } else if (
              OrderRequestOverviewData.value.relation_payment.payment_by ===
              OrderRequestPaymentMethod.PrivatePerson
            ) {
              if (
                !OrderRequestOverviewData.value.relation_payment.payment_person
              ) {
                flag = true;
              }
            }
          }
        }
      }
      return flag;
    });

    const showPaymentInfomationTip = () => {
      Swal.fire({
        text: t("orderRequest.paymentErrorTwoTip"),
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("common.okGotIt"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    };

    // const getInvoiceList = async () => {
    //   const { data } = await ApiOrderRequest.getOrderRequesV2InvoicetList({
    //     merchant_request_id: route.params.id,
    //   });
    //   if (data.code === 0) {
    //     options.value.orderRequestInvoice = data.data;
    //   }
    // };

    const fileExport = () => {
      options.value.downloadDisabled = true;
      ApiOrderRequest.exportOrderRequest({
        merchant_request_id: OrderRequestOverviewData.value.id,
      })
        .then((data) => {
          options.value.downloadDisabled = false;
          commonExportFile(data);
          if (
            OrderRequestOverviewData.value.order_progress ===
            OrderRequestProgress.New
          ) {
            getFormInfo();
          }
        })
        .catch((error) => {
          options.value.downloadDisabled = false;
          console.log(error);
        });
    };

    const changeTabIndex = (index: number) => {
      options.value.tabIndex = index;
    };

    const addressType = ref(0);

    const toEdit = () => {
      options.value.isEdit = !options.value.isEdit;
      options.value.tabIndex = 0;
    };

    const getIsEdit = () => {
      let flag = false;
      switch (route.name) {
        case "merchant-order-order-overview-add":
        case "merchant-order-order-request-add":
        case "merchant-order-order-request-overview":
        case "merchant-order-order-overview-overview":
          if (
            OrderRequestOverviewData.value.order_progress <
              OrderRequestProgress.Confirmed &&
            OrderRequestOverviewData.value.order_progress !=
              OrderRequestProgress.Rejected
          ) {
            flag = true;
          }
          break;
      }
      options.value.isEdit = flag;
      options.value.tabIndex = 0;
    };

    const isOrderRequest = computed(() => {
      let flag = false;
      switch (route.name) {
        case "merchant-order-order-overview-add":
        case "merchant-order-order-request-add":
        case "merchant-order-order-request-overview":
          if (
            OrderRequestOverviewData.value.order_progress <
            OrderRequestProgress.Confirmed
          ) {
            flag = true;
          }
          break;
      }
      return flag;
    });

    const isEdit = computed(() => {
      return options.value.isEdit;
    });

    const isAdd = computed(() => {
      let flag = false;
      flag = isDraft.value;
      if (
        OrderRequestOverviewData.value.order_progress ===
        OrderRequestProgress.Draft
      ) {
        flag = true;
      }
      return flag;
    });

    const isDraft = computed(() => {
      let flag = false;
      switch (route.name) {
        case "merchant-order-order-overview-add":
        case "merchant-order-order-request-add":
          flag = true;
          break;
      }
      return flag;
    });

    const isEditPage = computed(() => {
      let flag = false;
      switch (route.name) {
        case "merchant-order-order-overview-add":
        case "merchant-order-order-request-add":
        case "merchant-order-order-request-overview":
        case "merchant-order-order-overview-overview":
        case "merchant-order-order-processing-overview":
          flag = true;
          break;
      }
      return flag;
    });

    // 修改地址（账单和发货地址）
    const addressChange = (value: number) => {
      addressType.value = value;
    };

    // 修改地址信息
    const updateAddress = (data: OrderRequestOverviewShowAddressTaxRates) => {
      if (addressType.value === 1) {
        OrderRequestOverviewData.value.invoice_address = data.id as number;
        OrderRequestOverviewData.value.__show.invoice_address = data;
        OrderRequestOverviewData.value.__show.tax_rates = data.__show.tax_rates;
        OrderRequestOverviewData.value.__show.default_tax_rate =
          data.__show.default_tax_rate;
        updateProductTax(
          OrderRequestOverviewData.value.__show.default_tax_rate
        );
      } else {
        OrderRequestOverviewData.value.shipping_address = data.id as number;
        OrderRequestOverviewData.value.__show.shipping_address = data;
      }
      options.value.isEdit = true;
      options.value.tabIndex = 0;
    };

    // 修改支付信息
    const updatePaymentMethod = (data) => {
      OrderRequestOverviewData.value.relation_payment.payment_method =
        data.payment_method.value;
      // OrderRequestOverviewData.value.relation_payment.__show.payment_method =
      //   data.payment_method.label;
      OrderRequestOverviewData.value.relation_payment.payment_by =
        data.payment_by.value;
      // OrderRequestOverviewData.value.relation_payment.__show.payment_by =
      //   data.payment_by.label;
      OrderRequestOverviewData.value.relation_payment.payment_person =
        data.payment_person;
    };

    // 修改商户信息
    const updateMerchant = (data) => {
      if (
        Number(OrderRequestOverviewData.value.merchant) !==
        Number(data.merchant.value)
      ) {
        OrderRequestOverviewData.value.relation_items = [];
        OrderRequestOverviewData.value.shipping_tax_rate = 0;
        OrderRequestOverviewData.value.__show.tax_rates = [];
        OrderRequestOverviewData.value.__show.default_tax_rate = 0;
        OrderRequestOverviewData.value.invoice_address = 0;
        OrderRequestOverviewData.value.__show.invoice_address = Object.assign(
          {},
          orderRequestOverviewShowAddressData
        );
      }
      OrderRequestOverviewData.value.merchant = data.merchant.value;
      OrderRequestOverviewData.value.__show.merchant = data.merchant.label;
      OrderRequestOverviewData.value.__show.tax_rates =
        data.merchant.entity_data.__show.tax_rates;
      OrderRequestOverviewData.value.channel = data.channel.value;
      OrderRequestOverviewData.value.__show.channel_type =
        data.channel.channel_type;
      OrderRequestOverviewData.value.__show.channel = data.channel.label;
      updateProductTax(OrderRequestOverviewData.value.__show.default_tax_rate);
    };

    const updateProductTax = (tax: number) => {
      OrderRequestOverviewData.value.relation_items.forEach((item) => {
        item.tax_rate = tax;
      });
    };

    const getShowInfo = async () => {
      if (isDraft.value) return;
      const { data } = await ApiOrderRequest.getOrderRequestV2Info({
        merchant_request_id: route.params.id,
      });
      if (data.code == 0) {
        if (
          data.data.type === OrderRequestType.DoubleSaleOrder &&
          (data.data.order_progress === OrderRequestProgress.New ||
            data.data.order_progress === OrderRequestProgress.Requested)
        ) {
          data.data.relation_items.forEach((item) => {
            item.confirmed_qty = item.request_qty;
          });
        } else if (data.data.order_progress === OrderRequestProgress.New) {
          data.data.relation_items.forEach((item) => {
            if (item.confirmed_qty === 0) {
              item.confirmed_qty = "";
            }
          });
        }
        OrderRequestOverviewData.value = data.data;
      }
    };

    const getOrderRequestV2InitialInfo = async () => {
      if (!isDraft.value) return;
      const { data } = await ApiOrderRequest.getOrderRequestV2InitialInfo({});
      if (data.code == 0) {
        OrderRequestOverviewData.value = data.data;
      }
    };

    const fileExportOrderNotice = async () => {
      if (disabledExportOrderNotice.value) true;
      disabledExportOrderNotice.value = true;
      ApiSalesOrders.exportOrderNotice({
        order_id: route.params.id,
      })
        .then((data) => {
          disabledExportOrderNotice.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportOrderNotice.value = false;
          console.log(error);
        });
    };

    const getFormInfo = (flag = true) => {
      loading.value = true;
      Promise.all([
        getShowInfo(),
        getOrderRequestV2InitialInfo(),
        // getInvoiceList(),
      ])
        .then(() => {
          loading.value = false;
          if (flag) {
            getIsEdit();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const dsTotalGrossAmount = computed(() => {
      return OrderRequestOverviewData.value.relation_items.reduce(
        (sum, item) => {
          return sum + parseFloat(item.total_gross_amount);
        },
        0
      );
    });

    const getOrderTotalStart = computed(() => {
      const str = Number(dsTotalGrossAmount.value).toFixed(2);
      const result = str.substring(0, str.lastIndexOf("."));
      return result;
    });

    const getOrderTotalEnd = computed(() => {
      const str = Number(dsTotalGrossAmount.value).toFixed(2);
      let result = str.substring(str.lastIndexOf("."), str.length);
      if (OrderRequestOverviewData.value.__show.currency_code == "€") {
        result = result.replace(".", ",");
      }
      return result;
    });

    const isDoubleSale = computed(() => {
      return OrderRequestOverviewData.value.is_ds_order === 1;
    });

    onMounted(() => {
      getFormInfo();
      printConnect();
    });

    onUnmounted(() => {
      printClose();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      formatDateOriginTimeZone,
      CommonArrayToString,
      getMerchantOrderRouterPath,
      OrderRequestProgress,
      OrderRequestPaymentMethod,
      getOrderRequestProgressClass,
      childMainRef,
      loading,
      disabledExportOrderNotice,
      OrderRequestOverviewData,
      options,
      isShowPaymentInfomationTip,
      showPaymentInfomationTip,
      fileExport,
      changeTabIndex,
      toEdit,
      isOrderRequest,
      isEdit,
      isAdd,
      isDraft,
      isEditPage,
      addressType,
      addressChange,
      updateAddress,
      updatePaymentMethod,
      updateMerchant,
      getOrderTotalStart,
      getOrderTotalEnd,
      fileExportOrderNotice,
      getFormInfo,
      isDoubleSale,
      isAuditView,
    };
  },
});
