
import { getMerchantOrderRouterPath } from "@/core/directive/function/orderRequest";
import { OrderRequestOverviewRelationRequests } from "@/core/directive/interface/orderRequest";
import { defineComponent } from "vue";

export default defineComponent({
  name: "common-tree-menu-item",
  props: {
    tree: {
      type: Object as () => OrderRequestOverviewRelationRequests,
      required: true,
    },
  },
  setup() {
    return {
      getMerchantOrderRouterPath,
    };
  },
});
