
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiOrderRequest } from "@/core/api";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { ShipmentStatus } from "@/core/directive/type/shipment";
import {
  OrderRequestOverviewShowAddress,
  OrderRequestOverviewShowAddressTaxRates,
} from "@/core/directive/interface/orderRequest";
import EmptyQuery from "@/components/layout/EmptyQuery.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "order-request-update-address-modal",
  props: {
    isDraft: {
      type: Boolean,
      default: false,
    },
    addressType: {
      type: Number,
      default: 0,
    },
    merchantId: {
      type: Number,
      default: 0,
    },
    taxRates: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { EmptyQuery },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updateAddressRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      search: "",
      items: [] as OrderRequestOverviewShowAddressTaxRates[],
    });

    const rules = ref({
      shipping_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pick_up_by: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      addressLoading: false,
    });

    const getAddress = async () => {
      options.value.addressLoading = true;
      const { data } = await ApiOrderRequest.orderRequestV2SearchAddress({
        address_type: props.addressType === 1 ? "invoice" : "shipping",
        keyword: formData.value.search,
        merchant_id: props.merchantId,
      });
      options.value.addressLoading = false;
      if (data.code == 0) {
        formData.value.items = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const debounceSearch = _.debounce(getAddress, 1000);

    const searchItems = () => {
      if (formData.value.search.trim() !== "") {
        debounceSearch();
      } else {
        formData.value.items = [];
      }
    };

    const removeSearch = () => {
      formData.value.search = "";
    };

    const changeAddress = (item: OrderRequestOverviewShowAddress) => {
      // if (!props.isDraft) {
      //   updateAddress(item);
      // } else {
      //   hideModal(updateAddressRef.value);
      //   emit("update-list", item);
      // }
      if (props.addressType === 1 && isChangeTaxRates.value(item)) {
        Swal.fire({
          text: t("orderRequest.changingAddressWarning"),
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-light",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            hideModal(updateAddressRef.value);
            emit("update-list", item);
          }
        });
      } else {
        hideModal(updateAddressRef.value);
        emit("update-list", item);
      }
    };

    const isChangeTaxRates = computed(() => {
      return (item) => {
        let isflag = false;
        if (props.taxRates.length !== item.__show.tax_rates.length) {
          isflag = true;
        } else {
          item.__show.tax_rates.map((sub) => {
            if (props.taxRates.indexOf(sub) === -1) {
              isflag = true;
            }
          });
        }
        return isflag;
      };
    });

    const updateAddress = async (item: OrderRequestOverviewShowAddress) => {
      loading.value = true;
      const { data } = await ApiOrderRequest.orderRequestV2SelectAddress({
        address_type: props.addressType === 1 ? "invoice" : "shipping",
        address_id: item.id,
        merchant_request_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        hideModal(updateAddressRef.value);
        emit("update-list", item);
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
      debounceSearch();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(updateAddressRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      }, 0);
    };

    onMounted(() => {
      modalShowListener(updateAddressRef.value, () => {
        init();
      });
      modalHideListener(updateAddressRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      commonChangeDefaultDate,
      ShipmentStatus,
      formRef,
      submitButtonRef,
      submitButton,
      updateAddressRef,
      props,
      loading,
      formData,
      rules,
      options,
      searchItems,
      removeSearch,
      changeAddress,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
