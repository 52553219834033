
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiBase, ApiMerchant } from "@/core/api";
import {
  commonChangeDefaultDate,
  modalShowListener,
removeReadonly,
} from "@/core/directive/function/common";
import {
  ChannelOption,
  MerchantOption,
} from "@/core/directive/interface/common";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";
import channel from "@/core/api/channel";

export default defineComponent({
  components: { CommonChannelOption },
  name: "order-request-update-merchant-modal",
  props: {
    isDraft: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    merchantId: {
      type: Number,
      default: 0,
    },
    channelId: {
      type: Number,
      default: 0,
    },
    addressType: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance = getCurrentInstance();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updateMerchantRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      merchant: "",
      merchantLoading: false,
      merchantOptions: [] as MerchantOption[],
      channel: "",
      channelLoading: false,
      channelOptions: [] as ChannelOption[],
    });

    const rules = ref({
      merchant: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({});

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const getMerchantSourceData = async (value, id?) => {
      formData.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: {
          include_fields: ["name", "country_iso_2", "address", "tax_rates"],
        },
      });
      formData.value.merchantLoading = false;
      if (data.code == 0) {
        formData.value.merchantOptions = data.data;
        if (id && data.data.length > 0) {
          formData.value.merchant = data.data[0];
          getSalesChannelsData(data.data[0].value, true, props.channelId);
        }
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getSalesChannelsData = async (merchantId?, remake?, id?) => {
      if (remake) {
        formData.value.channel = "";
        formData.value.channelOptions = [];
        formData.value.channelLoading = true;
        const params = id
          ? {
              merchant_id: merchantId,
              id: String(id),
              status: "10",
            }
          : {
              max_item: "all",
              merchant_id: merchantId,
              status: "10",
            };
        const { data } = await ApiMerchant.getSalesChannelsData(params);
        formData.value.channelLoading = false;
        if (data.code == 0) {
          data.data.forEach((item, index) => {
            item.key = index;
          });
          formData.value.channelOptions = data.data;
          if (id && data.data.length > 0) {
            formData.value.channel = data.data[0];
          }
        }
      }
    };

    const getFormInfo = (value = "", id?) => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([getMerchantSourceData("", id)])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      const data = {
        merchant: formData.value.merchant,
        channel: formData.value.channel,
      };
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          emit("update-list", data);
          handleDiscard();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(updateMerchantRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        if (props.merchantId === 0) {
          getFormInfo();
        } else {
          getFormInfo("", String(props.merchantId));
        }
      }, 0);
    };

    onMounted(() => {
      modalShowListener(updateMerchantRef.value, () => {
        init();
      });
      modalHideListener(updateMerchantRef.value, () => {
        resetForm();
      });
      cancelReadonly(false);
    });

    return {
      t,
      commonChangeDefaultDate,
      formRef,
      submitButtonRef,
      submitButton,
      updateMerchantRef,
      props,
      loading,
      formData,
      rules,
      options,
      cancelReadonly,
      searchMerchantSourceItems,
      getSalesChannelsData,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
