/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-05 09:44:53
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-02-09 10:23:51
 */
export enum ShipmentStatus {
  Created = 10,
  PickUp = 20,
  InTransit = 30,
  OutForDelivery = 35,
  Delivered = 40,
  Rejected = 50,
}
