import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-966b580e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "menu-item px-3",
  "data-kt-menu-trigger": "hover",
  "data-kt-menu-placement": "right-start"
}
const _hoisted_2 = {
  href: "#",
  class: "menu-link px-3"
}
const _hoisted_3 = { class: "menu-title text-line-clamp-1" }
const _hoisted_4 = {
  key: 0,
  class: "menu-sub menu-sub-dropdown py-4"
}
const _hoisted_5 = { class: "svg-icon svg-icon-8 me-1" }
const _hoisted_6 = { class: "menu-title text-line-clamp-1" }
const _hoisted_7 = {
  key: 0,
  class: "menu-sub menu-sub-accordion pt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_tree_menu_item = _resolveComponent("common-tree-menu-item", true)!
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(_ctx.tree.request_number), 1)
          ]),
          (!(_ctx.tree.child instanceof Array))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.tree], (item, index) => {
                  return (_openBlock(), _createBlock(_component_common_tree_menu_item, {
                    key: index,
                    tree: item.child
                  }, null, 8, ["tree"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["menu-item menu-accordion show", {
      hover: _ctx.tree.current_request === 1,
    }])
    }, [
      _createVNode(_component_router_link, {
        class: "menu-link py-3",
        to: {
        name: _ctx.$route.name,
        params: {
          id: _ctx.tree.request_id,
        },
      },
        replace: "",
        "active-class": "m-0"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg_icon, { src: "media/svg/custom/union.svg" })
          ]),
          _createElementVNode("span", _hoisted_6, "#" + _toDisplayString(_ctx.tree.request_number), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      (!(_ctx.tree.child instanceof Array))
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.tree], (item, index) => {
              return (_openBlock(), _createBlock(_component_common_tree_menu_item, {
                key: index,
                tree: item.child
              }, null, 8, ["tree"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}