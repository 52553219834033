
import { OrderRequestOverviewRelationRequests } from "@/core/directive/interface/orderRequest";
import { defineComponent } from "vue";
import CommonTreeMenuItem from "./CommonTreeMenuItem.vue";
import { getMerchantOrderRouterPath } from "@/core/directive/function/orderRequest";
import config from "@/core/config/ApiConfig";

export default defineComponent({
  components: { CommonTreeMenuItem },
  name: "common-tree-menu",
  props: {
    trees: {
      type: Object as () => OrderRequestOverviewRelationRequests | string[],
      required: true,
    },
    treesNumber: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      config,
      getMerchantOrderRouterPath,
    };
  },
});
