import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_update_address",
  ref: "updateAddressRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_update_address_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "fs-6 text-gray-400" }
const _hoisted_7 = { class: "fw-bolder" }
const _hoisted_8 = { class: "fs-6 text-gray-400" }
const _hoisted_9 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_10 = { class: "svg-icon svg-icon-1" }
const _hoisted_11 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_12 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_13 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "card-box pt-10" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "symbol symbol-40px me-4" }
const _hoisted_18 = { class: "symbol-label fs-2 fw-semibold bg-danger text-inverse-danger" }
const _hoisted_19 = { class: "d-flex align-items-center flex-row-fluid flex-wrap" }
const _hoisted_20 = { class: "flex-grow-1" }
const _hoisted_21 = {
  key: 0,
  class: "badge badge-light fw-bold ms-2"
}
const _hoisted_22 = {
  key: 1,
  class: "badge badge-light fw-bold ms-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_empty_query = _resolveComponent("empty-query")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form, {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.formData,
          rules: _ctx.rules,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("div", null, [
                _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("orderRequest.invoiceAddress")), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("orderRequest.invoiceAddressSubTitle")), 1)
              ], 512), [
                [_vShow, _ctx.props.addressType === 1]
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.t("orderRequest.shippingAddress")), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t("orderRequest.shippingAddressSubTitle")), 1)
              ], 512), [
                [_vShow, _ctx.props.addressType === 2]
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                ])
              ])
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.search) = $event)),
                  onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                  class: "form-control form-control-solid ps-15",
                  placeholder: 
                  _ctx.props.addressType === 1
                    ? _ctx.t('orderRequest.invoiceAddressPlaceholder')
                    : _ctx.t('orderRequest.shippingAddressPlaceholder')
                
                }, null, 40, _hoisted_14), [
                  [_vModelText, _ctx.formData.search]
                ]),
                _withDirectives(_createElementVNode("span", {
                  class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
                  style: {"cursor":"pointer"},
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
                }, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                ], 512), [
                  [_vShow, _ctx.formData.search != '']
                ])
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "search" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "items" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.items, (item, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: "d-flex flex-stack py-4 cursor-pointer",
                    key: index,
                    onClick: ($event: any) => (_ctx.changeAddress(item))
                  }, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, _toDisplayString(item.country_iso_2), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("span", null, _toDisplayString(item.name) + ", ", 1),
                        _createElementVNode("span", null, _toDisplayString(item.address) + ", ", 1),
                        _createElementVNode("span", null, _toDisplayString(item.zip_code) + ", ", 1),
                        _createElementVNode("span", null, _toDisplayString(item.city), 1),
                        (_ctx.props.addressType === 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(item.vat_no), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(item.warehouse), 1))
                      ])
                    ])
                  ], 8, _hoisted_16)), [
                    [_vShow, _ctx.formData.items.length > 0]
                  ])
                }), 128)),
                _withDirectives(_createElementVNode("div", null, [
                  _createVNode(_component_empty_query)
                ], 512), [
                  [_vShow, _ctx.formData.items.length === 0]
                ])
              ])), [
                [_directive_loading, _ctx.options.addressLoading]
              ])
            ])), [
              [_directive_loading, _ctx.loading]
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ])
  ], 512))
}