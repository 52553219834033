import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9150432a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-query-box w-100 d-flex flex-column align-items-center justify-content-center" }
const _hoisted_2 = { class: "svg-icon svg-icon-4x m-0" }
const _hoisted_3 = { class: "mt-10 mb-2" }
const _hoisted_4 = { class: "fs-7 text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/files/fil024.svg" })
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("common.noResultFound")), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("common.plsResultAgain")), 1)
  ]))
}